import { graphql, useStaticQuery } from 'gatsby';

export const useProjectImages = () => {
  const {
    allFile: { nodes: images }
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "projects" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 70, width: 600, breakpoints: [350, 450, 600])
            }
            name
          }
        }
      }
    `
  );

  return images;
};
