export const COOKIES = {
  KEY: 'cookies-consent',
  ACCEPTED_VALUE: 'YES',
  DECLINED_VALUE: 'NO'
};

export const PRODUCTION_ENV = 'production';

export const ESCAPE_KEY = 'Escape';
export const TAB_KEY = 'Tab';
export const ENTER_KEY = 'Enter';

export const RESUME_LINK = 'https://drive.google.com/file/d/1kBHoTzrbvsGfp9lr1kUjEy-CLaon9hhp';

export const PAGES = {
  HOME: '/',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service'
};
