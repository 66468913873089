import React from 'react';
import './Category.styles.scss';

const Category = ({ name, icons = [] }) => {
  return (
    <div className={`skills-category`}>
      <h3 className='skills-category__title heading-sm'>{name}</h3>
      <ul
        className='skills-category__icons'
        aria-label='technologies'
      >
        {icons.map(({ Icon, label }, i) => (
          <li
            key={i}
            className='skills-category__icon'
          >
            <Icon aria-hidden='true' />
            <span className='body-md'>{label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Category;
