import React, { useEffect, useRef } from 'react';
import './PageNotFound.styles.scss';
import { Link } from 'gatsby';
import lottie from 'lottie-web';
import animation from '../../../static/animations/page-not-found.json';
import Icons from '../../modules/icons';

const { HomeIcon } = Icons;

const PageNotFound = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation
    });
    return () => anim.destroy();
  }, []);

  return (
    <section className='page-not-found container'>
      <div
        className='page-not-found__animation'
        ref={animationContainer}
        aria-hidden="true"
      ></div>
      <h1 className='page-not-found__title heading-md'>The page you're looking for wasn't found</h1>
      <Link
        to='/'
        replace
        className='button page-not-found__back-home'
      >
        Back to home
        <HomeIcon className='heading-sm' aria-hidden="true" />
      </Link>
    </section>
  );
};

export default PageNotFound;
