import React from 'react';
import './Hero.styles.scss';
import SocialIcons from '../SocialIcons/SocialIcons.component';
import Button from '../Button/Button.component';
import Icons from '../../modules/icons';
import { RESUME_LINK } from '../../constants';
import Illustration from './Illustration/Illustration.component';
import { sendGTAGEvent } from '../../utils';

const { DocumentIcon } = Icons;

const Hero = () => {
  const onViewResume = () => sendGTAGEvent('view_resume');

  return (
    <section
      className='hero'
      id='hero'
    >
      <div className='container hero__content'>
        <div className='hero__introduction'>
          <h1 className='hero__title heading-xxl'>Software Developer</h1>
          <p className='hero__summary body-lg'>
            I enjoy learning about technologies, as each offers a unique perspective to solving problems and challenges
            me. I find it rewarding to apply what I've learned.
          </p>
          <SocialIcons iconClassName='heading-lg' />
          <Button
            href={RESUME_LINK}
            aria-label='View resume in new tab'
            className='hero__resume'
            onClick={onViewResume}
          >
            <span>View resume</span>
            <DocumentIcon
              className='heading-sm'
              aria-hidden='true'
            />
          </Button>
        </div>
        <div
          className='hero__image'
          aria-hidden='true'
        >
          <Illustration />
        </div>
      </div>
    </section>
  );
};

export default Hero;
