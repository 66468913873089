import { useEffect } from 'react';

export const useWindowEvent = (type, listener, isActive, options) => {
  useEffect(() => {
    if (isActive) {
      window.addEventListener(type, listener, options);
    }
    return () => {
      window.removeEventListener(type, listener, options);
    };
  }, [type, listener, isActive, options]);
};
