import React from 'react';
import { useSiteMetadata } from '../hooks';

const Seo = ({ title, description, URLPathName, previewImageURL, indexPage = true }) => {
  const { title: defaultTitle, siteUrl, faviconURL } = useSiteMetadata();

  const seo = {
    title: title ?? defaultTitle,
    description,
    previewImageURL: `${siteUrl}${previewImageURL}`,
    url: `${siteUrl}${URLPathName ?? ``}`,
    faviconURL: `${siteUrl}${faviconURL}`
  };

  return (
    <>
      <html lang='en' />
      <title>{seo.title}</title>
      <meta
        name='description'
        content={seo.description}
      />
      <meta
        name='image'
        content={seo.previewImageURL}
      />
      <meta
        property='og:url'
        content={seo.url}
      />
      <meta
        property='og:title'
        content={seo.title}
      />
      <meta
        property='og:description'
        content={seo.description}
      />
      <meta
        property='og:image'
        content={seo.previewImageURL}
      />
      <meta
        name='twitter:card'
        content='summary_large_image'
      />
      <meta
        name='twitter:title'
        content={seo.title}
      />
      <meta
        name='twitter:url'
        content={seo.url}
      />
      <meta
        name='twitter:description'
        content={seo.description}
      />
      <meta
        name='twitter:image'
        content={seo.previewImageURL}
      />
      <meta
        name='robots'
        content={indexPage ? 'index, follow' : 'noindex, nofollow'}
      />
      <link
        rel='icon'
        href={seo.faviconURL}
        type='image/png'
      />
    </>
  );
};

export default Seo;