import React from 'react';
import { isProd } from '../utils';


const GoogleTagManager = () => {
  if (!isProd()) {
    return null;
  }
  return (
    <>
      <link
        rel='preconnect'
        href='https://www.googletagmanager.com'
      />
      <link
        rel='dns-prefetch'
        href='https://www.googletagmanager.com'
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`}
      />
    </>
  );
};

export default GoogleTagManager;