import React from 'react';
import './Button.styles.scss';

const Button = ({ href, onClick, children, ...otherProps }) => {
  return href ? (
    <a
      {...otherProps}
      target='_blank'
      href={href}
      className={`button body-lg ${otherProps?.className}`}
      rel='noopener noreferrer'
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <button
      {...otherProps}
      onClick={onClick}
      className={`button body-lg ${otherProps?.className}`}
    >
      {children}
    </button>
  );
};

export default Button;
