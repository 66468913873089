import React from 'react';
import { Layout } from './src/components';
import { initializeGTAG, sendGTAGEvent } from './src/utils';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  initializeGTAG(process.env.GATSBY_GOOGLE_ANALYTICS_ID);
};

export const onRouteUpdate = ({ location }) => {
  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  sendGTAGEvent(`page_view`, { page_path: pagePath });
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	const currentPosition = getSavedScrollPosition(location);

	setTimeout(() => {
		window.scrollTo(...(currentPosition || [0, 0]));
	}, 1);

	return false;
};
