import './ContactForm.styles.scss';
import React, { useState } from 'react';
import { Form } from '../../../modules/bootstrap';
import Button from '../../Button/Button.component';
import { toast } from 'react-toastify';
import { sendFormData } from '../../../modules/api';
import Icons from '../../../modules/icons';
import { sendGTAGEvent } from '../../../utils';

const { MailIcon } = Icons;

const ContactForm = () => {
  const [wasValidated, setWasValidated] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    message: ''
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    sendGTAGEvent('contact_form_submitted', {
      isValid: form.checkValidity()
    });
    if (form.checkValidity() && !isSending) {
      setIsSending(true);
      const successfullySent = await sendFormData(formData, form.getAttribute('name'));
      if (successfullySent) {
        form.reset();
        setWasValidated(false);
        toast.success('Message sent!');
      } else {
        toast.error('There was an error. Please try again.');
      }
      setIsSending(false);
    } else {
      setWasValidated(true);
    }
  };

  return (
    <Form
      noValidate
      validated={wasValidated}
      name='contact'
      onSubmit={handleSubmit}
      data-netlify='true'
      className='contact-form'
    >
      <Form.Group
        className='contact-form__group'
        controlId='email'
      >
        <Form.Label className='contact-form__label body-md'>Email</Form.Label>
        <Form.Control
          type='email'
          placeholder='john@gmail.com'
          required
          name='email'
          onChange={handleChange}
          className='contact-form__input body-sm'
          pattern='^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$'
        />
        <Form.Control.Feedback
          className='contact-form__invalid body-sm'
          type='invalid'
        >
          Please enter a valid email
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group
        className='contact-form__group'
        controlId='message'
      >
        <Form.Label className='contact-form__label body-md'>Message</Form.Label>
        <Form.Control
          name='message'
          as='textarea'
          rows={3}
          required
          placeholder='Hi...'
          minLength={1}
          onChange={handleChange}
          className='contact-form__textarea body-sm'
        />
        <Form.Control.Feedback
          className='contact-form__invalid body-sm'
          type='invalid'
        >
          Please enter a message
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        type='submit'
        disabled={isSending}
        className='contact-form__submit'
      >
        {isSending ? 'Sending message...' : 'Send message'}
        <MailIcon
          className='heading-sm'
          aria-hidden='true'
        />
      </Button>
    </Form>
  );
};

export default ContactForm;
