import { AnchorLink } from 'gatsby-plugin-anchor-links';
import './PrivacyPolicy.styles.scss';
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='container privacy-policy'>
      <h1 className='privacy-policy__title heading-lg'>Privacy Policy</h1>
      <section className='privacy-policy__section'>
        <p className='body-md mb-1 privacy-policy__text'>
          This privacy notice describes how and why we might collect, store, use, and/or share (“process“) your
          information when you use our services (“Services“), such as when you:
        </p>
        <ul className='body-md privacy-policy__text'>
          <li>Visit our website or any website that links to this privacy notice</li>
        </ul>
      </section>
      <section className='privacy-policy__section'>
        <h2 className='heading-sm privacy-policy__subheading mb-1'>What information do we collect?</h2>
        <p className='body-md mb-1 privacy-policy__text'>
          We collect personal information that you voluntarily provide to us. The personal information that we collect
          depends on the context of your interactions with us, the choices you make, and the features you use. The
          personal information we collect may include the following:
        </p>
        <ul className='body-md privacy-policy__text'>
          <li>
            <span>email</span>
          </li>
        </ul>
      </section>
      <section className='privacy-policy__section'>
        <h2 className='heading-sm privacy-policy__subheading mb-1'>How do we process your information?</h2>
        <p className='body-md mb-1 privacy-policy__text'>
          We process your personal information for a variety of reasons, depending on how you interact with the website,
          including:
        </p>
        <ul className='body-md privacy-policy__text'>
          <li>To respond to messages</li>
        </ul>
      </section>
      <section className='privacy-policy__section'>
        <h2 className='heading-sm privacy-policy__subheading mb-1'>
          What legal bases do we rely on to process your information?
        </h2>
        <p className='body-md privacy-policy__text'>
          We may process your information if you have given us specific permission (i.e., express consent) to use your
          personal information for a specific purpose, or in situations where your permission can be inferred (i.e.,
          implied consent).
        </p>
      </section>
      <section className='privacy-policy__section'>
        <h2 className='heading-sm privacy-policy__subheading mb-1'>
          When and with whom do we share your personal information?
        </h2>
        <p className='body-md privacy-policy__text'>
          We do not share your data with any third-party service providers, contractors, or agents (“third parties”).
        </p>
      </section>
      <section className='privacy-policy__section'>
        <h2 className='heading-sm privacy-policy__subheading mb-1'>
          Do we use cookies and other tracking technologies?
        </h2>
        <p className='body-md privacy-policy__text'>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information.
        </p>
      </section>
      <section className='privacy-policy__section'>
        <h2 className='heading-sm privacy-policy__subheading mb-1'>Do we make updates to this policy?</h2>
        <p className='body-md privacy-policy__text'>
          We may update this privacy policy from time to time. The updated version will be indicated by an updated
          “Revised” date and the updated version will be effective as soon as it is accessible. We encourage you to
          review this privacy notice frequently to be informed of how we are protecting your information.
        </p>
      </section>
      <section className='privacy-policy__section'>
        <h2 className='heading-sm privacy-policy__subheading mb-1'>How can you contact us about this notice?</h2>
        <p className='body-md privacy-policy__text'>
          If you have questions or comments about this notice, you can send a message using the{' '}
          <AnchorLink
            to={'/#contact'}
            title='contact form'
            className='link'
          />
          .
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
