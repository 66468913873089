import React from 'react';
import {
  SiDocker,
  SiPostgresql,
  SiNextdotjs,
  SiGit,
  SiGithub,
  SiLinkedin,
  SiReact,
  SiSass,
  SiMongodb,
  SiNodedotjs,
  SiJavascript,
  SiAngular,
  SiJira,
  SiGatsby,
  SiHtml5,
  SiCss3,
  SiGithubactions,
  SiRedux,
  SiTailwindcss,
  SiDotnet,
  SiTypescript,
  SiStorybook,
  SiLerna,
  SiPython,
  SiFlask,
  SiTeamcity,
  SiGoogleanalytics,
  SiBootstrap,
  SiAppium,
  SiCsharp,
  SiMui
} from 'react-icons/si';
import {
  IoDocumentTextOutline as DocumentIcon,
  IoChevronDown as ChevronCircleDownIcon,
  IoChevronUp as ChevronCircleUpIcon,
  IoGlobeOutline as GlobeIcon,
  IoCloseOutline as CloseIcon,
  IoMailOutline as MailIcon,
  IoInformationCircleOutline as InfoIcon,
  IoWarningOutline as WarningIcon,
  IoAlertCircleOutline as ErrorIcon,
  IoCheckmarkCircleOutline as SuccessIcon,
  IoTimeOutline as TimeIcon,
  IoLogoFigma as FigmaIcon,
  IoHomeOutline as HomeIcon
} from 'react-icons/io5';
import { DiMsqlServer } from 'react-icons/di';
import { PiCopyrightLight as CopyrightIcon } from 'react-icons/pi';

const AngularMaterialIcon = (props) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 128 128'
    width='1em'
    height='1em'
    color='#ffa726'
  >
    <path
      fill='currentColor'
      d='M63.934.656 5.25 21.66l8.863 77.719 49.82 27.77 49.887-27.77 9.059-77.719Zm0 0'
    />
    <path
      fill='currentColor'
      d='M63.934.656v126.492l49.886-27.77 9.059-77.718Zm0 0'
    />
    <path
      fill='#ffe0b2'
      d='m72.797 96.688-41.55-20.02 23.827-14.703L96.887 82.05Zm0 0'
    />
    <path
      fill='#fff3e0'
      d='m72.797 81.262-41.55-20.086 23.827-14.637 41.813 20.086Zm0 0'
    />
    <path
      fill='#fff'
      d='m72.797 65.84-41.55-20.09 23.827-14.703 41.813 20.086Zm0 0'
    />
  </svg>
);

const Icons = {
  ChevronCircleDownIcon,
  ChevronCircleUpIcon,
  AppiumIcon: (props) => (
    <SiAppium
      {...props}
      color='#EE376D'
    />
  ),
  TeamCityIcon: (props) => (
    <SiTeamcity
      {...props}
      color='#000000'
    />
  ),
  BootstrapIcon: (props) => (
    <SiBootstrap
      {...props}
      color='#7952B3'
    />
  ),
  GoogleAnalyticsIcon: (props) => (
    <SiGoogleanalytics
      {...props}
      color='#E37400'
    />
  ),
  MSQLIcon: (props) => (
    <DiMsqlServer
      {...props}
      color='#C32526'
    />
  ),
  GitHubActionsIcon: (props) => (
    <SiGithubactions
      {...props}
      color='#2088FF'
    />
  ),
  PythonIcon: (props) => (
    <SiPython
      {...props}
      color='#3776AB'
    />
  ),
  AngularMaterialIcon,
  FlaskIcon: (props) => (
    <SiFlask
      {...props}
      color='#000000'
    />
  ),
  StorybookIcon: (props) => (
    <SiStorybook
      {...props}
      color='#FF4785'
    />
  ),
  LernaIcon: (props) => (
    <SiLerna
      {...props}
      color='#334155'
    />
  ),
  TypeScriptIcon: (props) => (
    <SiTypescript
      {...props}
      color='#3178C6'
    />
  ),
  ReduxIcon: (props) => (
    <SiRedux
      {...props}
      color='#764ABC'
    />
  ),
  TailwindCSSIcon: (props) => (
    <SiTailwindcss
      {...props}
      color='#06B6D4'
    />
  ),
  DotNetIcon: (props) => (
    <SiDotnet
      {...props}
      color='#512BD4'
    />
  ),
  GitHubIcon: (props) => (
    <SiGithub
      {...props}
      color='#181717'
    />
  ),
  LinkedinIcon: (props) => (
    <SiLinkedin
      {...props}
      color='#0077B5'
    />
  ),
  CloseIcon,
  AngularIcon: (props) => (
    <SiAngular
      {...props}
      color='#0F0F11'
    />
  ),
  GatsbyIcon: (props) => (
    <SiGatsby
      {...props}
      color='#663399'
    />
  ),
  DockerIcon: (props) => (
    <SiDocker
      {...props}
      color='#2496ED'
    />
  ),
  JavaScriptIcon: (props) => (
    <SiJavascript
      {...props}
      color='#F7DF1E'
    />
  ),
  MongodbIcon: (props) => (
    <SiMongodb
      {...props}
      color='#47A248'
    />
  ),
  NodeJsIcon: (props) => (
    <SiNodedotjs
      {...props}
      color='#5FA04E'
    />
  ),
  ReactIcon: (props) => (
    <SiReact
      {...props}
      color='#61DAFB'
    />
  ),
  SassIcon: (props) => (
    <SiSass
      {...props}
      color='#c69'
    />
  ),
  PostgresqlIcon: (props) => (
    <SiPostgresql
      {...props}
      color='#4169E1'
    />
  ),
  HTMLIcon: (props) => (
    <SiHtml5
      {...props}
      color='#E34F26'
    />
  ),
  CSSIcon: (props) => (
    <SiCss3
      {...props}
      color='#1572B6'
    />
  ),
  NextJSIcon: (props) => (
    <SiNextdotjs
      {...props}
      color='#000000'
    />
  ),
  GitIcon: (props) => (
    <SiGit
      {...props}
      color='#F05032'
    />
  ),
  JiraIcon: (props) => (
    <SiJira
      {...props}
      color='#0052CC'
    />
  ),
  CSharpIcon: (props) => (
    <SiCsharp
      {...props}
      color='#9B4F96'
    />
  ),
  MaterialUIIcon: (props) => (
    <SiMui
      {...props}
      color='#007FFF'
    />
  ),
  DocumentIcon,
  GlobeIcon,
  MailIcon,
  InfoIcon,
  ErrorIcon,
  WarningIcon,
  SuccessIcon,
  TimeIcon,
  CopyrightIcon,
  FigmaIcon,
  HomeIcon
};

export default Icons;
