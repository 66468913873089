import { graphql, useStaticQuery } from 'gatsby';

export const useExperienceImages = () => {
  const {
    allFile: { nodes: images }
  } = useStaticQuery(
    graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "experiences" } }) {
          nodes {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 10, width: 100, breakpoints: [100])
            }
            name
          }
        }
      }
    `
  );

  return images;
};
