import React from 'react';
import './Illustration.styles.scss';

const Illustration = () => (
  <svg
    viewBox='0 0 436 366'
    xmlns='http://www.w3.org/2000/svg'
    className='hero-illustration'
  >
    <path
      d='M424.029.42H10.909C5.116.42.42 5.118.42 10.91v257.071c0 5.793 4.697 10.49 10.49 10.49h413.12c5.794 0 10.49-4.697 10.49-10.49V10.911c0-5.794-4.696-10.49-10.49-10.49z'
      className='screen'
    />
    <path
      d='M342.43 148H180v9.14h162.43V148zM295.47 167H180v9.14h115.47V167zM328 66H11v8h317v-8zM416 99H180v8h236v-8zM353 133H180v8h173v-8zM416 116H180v8h236v-8zM359 51H11v8h348v-8zM210 36H11v8h199v-8zM127 21H11v8h116v-8z'
      className='code'
    />
    <path
      d='M170.61 236.88c1.08 1.43 2.37 2.68 3.83 3.7 2.17 1.17 4.57 1.88 7.03 2.09l37.41 5.64c.37.03.73.15 1.04.35.42.46.62 1.07.55 1.69.17 7-.77 13.99-2.8 20.7-.11.55-.37 1.05-.76 1.45-.5.34-1.1.52-1.71.52-12.59 1.07-25.23.73-37.85.39-2.12.03-4.24-.2-6.31-.69-3.52-.98-6.39-3.39-9.04-5.86-5.68-5.29-28.69-26.53-30.08-29.42a78.192 78.192 0 01-4.45-12.8c-1.17-4.22 7.4-4.75 7.22-9.13-.12-3.03.24-6.06 1.08-8.98.62-2.6 1.92-4.99 3.75-6.94 1.29-1.17-.7-9.12 1.08-9.21 2.06 7.03 11.39 21.1 15.45 27.12 4.51 6.72 9.37 13.19 14.57 19.38h-.01z'
      className='hair'
    />
    <path
      d='M75.95 177.51a67.992 67.992 0 00-7.03 7.27c-5.18 6.36-8.63 13.93-11.84 21.48-8.54 20.09-15.99 41.57-13.77 63.29.04 1.31.4 2.58 1.03 3.73a7.266 7.266 0 004.02 2.59c4.11 1.31 8.51 1.37 12.89 1.41 16.89.15 33.8.3 50.68-.52 2.61-.13 5.38-.33 7.55-1.8 1.58-1.21 2.85-2.79 3.68-4.61 4.54-8.53 5.86-18.4 9.38-27.38 4.69-11.72 17.58-22.37 14.86-34.73-11.63-52.45-66.12-35.57-71.44-30.73h-.01z'
      className='hair'
    />
    <path
      d='M152.27 134.07c-1.98 9.62-4.06 19.5-9.38 27.79a21.478 21.478 0 01-8.12 7.99c-2.44 1.36-5.7 1.74-8.39 1.01-2.69-.73-4.61-3.34-7.07-.27-1.18 1.48-1.46 3.46-1.71 5.33l-.93 7.2c-.09.72-.21 1.49-.71 2.01-.34.36-.82.54-1.29.69-3.4 1.07-7.14.54-10.45-.77-3.31-1.31-6.28-3.35-9.21-5.37-.91-.63-1.91-1.4-1.99-2.51-.03-.42.09-.84.2-1.25 2.6-9.49 5.07-20.8 3.35-30.56-1.39-7.87 2.37-19.14 7.89-24.8 3.27-3.35 7.15-6.04 11.44-7.92 6.39-3.05 13.51-5.19 20.5-4.08 5.63.89 10.58 4.19 13.56 9.05 3.66 5.61 3.67 9.8 2.3 16.47l.01-.01z'
      className='skin'
    />
    <path
      d='M169.72 112.86c.97-8.01-3.11-15.78-10.25-19.52-3.11-1.55-6.56-2.2-9.91-3.09-7.85-2.11-15.39-5.6-23.44-6.35s-17.31 2.07-20.9 9.38c-.66 1.34-1.3 2.95-2.74 3.3-.79.08-1.58.01-2.34-.21-5.38-.95-10.22 3.64-12.65 8.53-3.91 7.91-4.11 17.13-3.26 25.91.79 8.12 2.86 16.9 9.38 21.85.64.49 1.59.94 2.22.41.28-.3.46-.68.5-1.09.66-3.08.9-6.23.73-9.38-.13-2.34-.5-4.83.11-7.14.61-2.31 2.57-4.5 4.96-4.43 2.22 0 4 2.06 4.55 4.22.39 2.2.31 4.45-.25 6.61.35.88 1.58-.18 1.79-1.09 1.62-7.36 1.98-15.6 7.09-21.1a8.994 8.994 0 012.2 3.83c1.56-1.61 3.9-2.2 6.04-1.5 1.48.65 2.85 1.54 4.03 2.64 12.45 9.72 39.54 6.83 42.16-11.78h-.02z'
      className='hair'
    />
    <path
      d='M79.54 189.51c-3.94 2.86-5.86 7.89-5.98 12.74-.12 4.85 5.02 35.64 9.86 44.38 3.75 6.8 11.57 24.54 23.44 29.44 3.52 1.45 55.49.84 79.61-1.77-.15-5.71-.43-11.41-.81-17.12.04-.75-.19-1.49-.64-2.1-.61-.63-1.48-4.77-2.34-4.77-14.06.06-28.24 4.36-42.31 4.69-2.04.13-4.09 0-6.11-.36-1.65-.41-3.23-1.06-4.69-1.92-10.85-6.14-16.85-18.2-21.1-29.93-3.25-9.05-5.86-18.41-10.55-26.86-1.66-3.01-3.15-5.94-6.43-7.27-3.86-1.56-8.38-1.73-11.95.86v-.01z'
      className='hair'
    />
    <path
      d='M184.4 249.21c7.3-.69 15.41.94 20.04 6.62.49.52.81 1.17.91 1.88 0 .63-.2 1.25-.56 1.77-2.73 4.54-8.66 5.61-13.64 7.41a35.66 35.66 0 00-7.37 3.66c-1.3 1.03-2.8 1.77-4.41 2.18-3.52.53-6.43-2.61-8.03-5.75-1.83-3.52-4.69-10.74-1.17-14.06 2.87-2.64 10.62-3.36 14.23-3.69v-.02zM244.86 247.41c1.3.74 2.42 1.76 3.27 2.99.85 1.26.96 2.88.3 4.25-.55.83-1.31 1.51-2.19 1.97-6.48 3.86-14.43 4.55-20.99 8.2-1.35.98-2.89 1.65-4.52 1.97a6.26 6.26 0 01-5.02-2.34c-1.2-1.49-2.11-3.19-2.71-5-7.68-20.16 20.81-19.07 31.85-12.04h.01z'
      className='skin'
    />
    <path
      d='M17.48 271.6h411.34c3.6 0 6.52 2.91 6.52 6.51 0 3.6-2.92 6.51-6.52 6.51H17.48c-3.6 0-6.52-2.91-6.52-6.51 0-3.6 2.92-6.51 6.52-6.51zM353.2 284.81H120.69v80.64H353.2v-80.64z'
      className='desk'
    />
    <path
      d='M122.28 284.81H30.95v80.64h91.33v-80.64z'
      className='desk'
    />
    <path
      d='M353.21 284.81H122.23v80.64h230.98v-80.64z'
      fill='url(#paint2_linear_12_103)'
    />
    <path
      d='M122.28 284.81H30.95v80.64h91.33v-80.64z'
      fill='url(#paint3_linear_12_103)'
    />
    <path
      d='M338.41 271.37H187.88a7.64 7.64 0 01-7.64-7.64 7.64 7.64 0 017.64-7.64h150.53a7.64 7.64 0 017.64 7.64 7.64 7.64 0 01-7.64 7.64z'
      className='desk'
    />
    <path
      d='M338.41 271.37H187.88a7.64 7.64 0 01-7.64-7.64 7.64 7.64 0 017.64-7.64h150.53a7.64 7.64 0 017.64 7.64 7.64 7.64 0 01-7.64 7.64z'
      fill='url(#paint4_linear_12_103)'
    />
    <path
      d='M240.08 271.4H355.6l30.47-77.27c1.37-3.49-.35-7.44-3.84-8.81-.79-.31-1.63-.47-2.47-.47h-90.65c-8.97.02-17.03 5.51-20.32 13.85l-28.7 72.7h-.01z'
      className='desk'
    />
    <path
      d='M245.95 271.48h109.72l28.94-73.4a6.44 6.44 0 00-6-8.81h-86.09c-8.53 0-16.2 5.21-19.34 13.15l-27.22 69.06h-.01z'
      className='desk'
    />
    <path
      d='M245.95 271.48h109.72l28.94-73.4a6.44 6.44 0 00-6-8.81h-86.09c-8.53 0-16.2 5.21-19.34 13.15l-27.22 69.06h-.01z'
      fill='url(#paint5_linear_12_103)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_12_103'
        x1={103.46}
        y1={287.95}
        x2={85.5899}
        y2={180.16}
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop
          offset={0.12}
          stopOpacity={0.75}
        />
        <stop
          offset={1}
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id='paint1_linear_12_103'
        x1={109.8}
        y1={281.94}
        x2={139.24}
        y2={215.69}
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop
          offset={0.12}
          stopOpacity={0.75}
        />
        <stop
          offset={1}
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id='paint2_linear_12_103'
        x1={266.29}
        y1={229.14}
        x2={215.82}
        y2={398.7}
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop
          offset={0.12}
          stopOpacity={0.75}
        />
        <stop
          offset={1}
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id='paint3_linear_12_103'
        x1={58.07}
        y1={237.18}
        x2={106.96}
        y2={469.06}
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop
          offset={0.12}
          stopOpacity={0.75}
        />
        <stop
          offset={1}
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id='paint4_linear_12_103'
        x1={262.83}
        y1={278.56}
        x2={263.52}
        y2={246.66}
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop
          offset={0.12}
          stopOpacity={0.75}
        />
        <stop
          offset={1}
          stopOpacity={0}
        />
      </linearGradient>
      <linearGradient
        id='paint5_linear_12_103'
        x1={312.96}
        y1={298.28}
        x2={317.69}
        y2={178.4}
        gradientUnits='userSpaceOnUse'
      >
        <stop />
        <stop
          offset={0.12}
          stopOpacity={0.75}
        />
        <stop
          offset={1}
          stopOpacity={0}
        />
      </linearGradient>
    </defs>
  </svg>
);

export default Illustration;
