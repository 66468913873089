import React, { useState } from 'react';
import './Projects.styles.scss';
import Project from './Project/Project.component';
import DetailsModal from './DetailsModal/DetailsModal.component';
import { getProjectElementId, sendGTAGEvent } from '../../utils';
import { PROJECTS } from './projects.config';
import { useProjectImages } from '../../hooks';

const Projects = () => {
  const images = useProjectImages();
  const [shownProjectIndex, setShownProjectIndex] = useState(null);

  const openModal = (newIndex) => {
    if (newIndex !== shownProjectIndex) {
      setShownProjectIndex(newIndex);
      document.body.style.overflowY = 'hidden';
      sendGTAGEvent('view_project', {
        project_name: PROJECTS[newIndex].title
      });
    }
  };

  const closeModal = () => {
    document.body.style.overflowY = 'visible';
    const projectOverview = document.getElementById(getProjectElementId(PROJECTS[shownProjectIndex]));
    if (projectOverview) {
      projectOverview.focus();
    }
    setShownProjectIndex(null);
  };

  return (
    <section
      id='projects'
      className='projects'
    >
      <div className='container'>
        <h2 className='projects__title heading-lg'>Projects</h2>
        <div className='projects__overview'>
          {PROJECTS.map((p, i) => {
            const img = images.find((i) => i.name === p.previewImgName) || null;
            return (
              <Project
                key={p.title}
                project={{ ...p, previewImg: img }}
                onClick={() => openModal(i)}
              />
            );
          })}
        </div>
      </div>
      <DetailsModal
        closeModal={closeModal}
        isVisible={shownProjectIndex !== null}
        project={PROJECTS[shownProjectIndex]}
      />
    </section>
  );
};

export default Projects;
