import React, { useRef, useEffect } from 'react';
import { CookiesConsent } from '../components';
import { updateGTAGConsent } from '../utils';
import { COOKIES } from '../constants';
import { toast } from 'react-toastify';

export const useCookiesConsent = () => {
  const toastId = useRef(null);

  const onAccept = () => {
    localStorage.setItem(COOKIES.KEY, COOKIES.ACCEPTED_VALUE);
    updateGTAGConsent({ analytics_storage: 'granted' });
    toast.dismiss(toastId.current);
  };

  const onDecline = () => {
    localStorage.setItem(COOKIES.KEY, COOKIES.DECLINED_VALUE);
    toast.dismiss(toastId.current);
  };

  useEffect(() => {
    const cookiesConsent = localStorage.getItem(COOKIES.KEY);
    if (cookiesConsent === null) {
      toastId.current = toast(
        <CookiesConsent
          onAccept={onAccept}
          onDecline={onDecline}
        />,
        {
          closeOnClick: false,
          closeButton: false,
          className: "toast__cookie-consent"
        }
      );
    }
  }, []);
};
