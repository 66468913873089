import { useEffect, useRef } from 'react';
import { TAB_KEY } from '../constants';

export const useFocusTrap = (isActive) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!isActive || !ref.current) return;

    const focusableElementsString = 'button, [href], [tabindex]:not([tabindex="-1"])';
    const focusableElements = ref.current.querySelectorAll(focusableElementsString);
    const firstFocusableElement = focusableElements[0];
    const lastFocusableElement = focusableElements[focusableElements.length - 1];

    const handleKeyDown = (event) => {
      if (event.key === TAB_KEY) {
        if (event.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            event.preventDefault();
            lastFocusableElement.focus();
          }
        } else {
          if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            firstFocusableElement.focus();
          }
        }
        if (![...focusableElements].includes(document.activeElement)) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    firstFocusableElement.focus();
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive]);

  return ref;
};
