import React from 'react';
import './Footer.styles.scss';
import { useBuildDate } from '../../hooks';
import Icons from '../../modules/icons';
import { Link } from 'gatsby';
import { PAGES } from '../../constants';
import SocialIcons from '../SocialIcons/SocialIcons.component';

const { TimeIcon, CopyrightIcon } = Icons;

const currentYear = new Date().getFullYear();

const LINKS = [
  {
    label: 'Privacy Policy',
    href: PAGES.PRIVACY_POLICY
  },
  {
    label: 'Terms of Service',
    href: PAGES.TERMS_OF_SERVICE
  }
];

const Footer = () => {
  const lastUpdated = useBuildDate();

  return (
    <footer className='footer'>
      <div className='container footer__content'>
        <div className='footer__row footer__row--reverse'>
          <div className='footer__last-updated body-md'>
            <TimeIcon
              className='footer__icon'
              aria-hidden='true'
            />
            Last Updated: {lastUpdated}
          </div>
          <SocialIcons iconClassName='heading-sm' />
        </div>
        <div className='footer__row'>
          <div className='footer__copyright body-md'>
            <CopyrightIcon
              className='footer__icon'
              aria-hidden='true'
            />
            {currentYear} Chandra Panta Chhetri. All Rights Reserved.
          </div>
          <ul
            className='footer__links body-md'
            aria-label='links'
          >
            {LINKS.map(({ label, href }) => (
              <li key={label}>
                <Link
                  className='link'
                  to={href}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
