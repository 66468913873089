import React from 'react';
import './Skills.styles.scss';
import Category from './Category/Category.component';
import { CATEGORIES } from './skills.config';

const Skills = () => {
  return (
    <section
      id='skills'
      className='skills'
    >
      <div className='container'>
        <h2 className='skills__title heading-lg'>Skills</h2>
        <div className='skills__overview'>
          {CATEGORIES.map(({ name, icons }) => (
            <Category
              name={name}
              icons={icons}
              key={name}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
