import { useEffect, useState, useRef } from 'react';

const DEFAULT_OPTIONS = {
  rootMargin: '-60% 0px'
};

export const useScrollSpy = (elementSelectors, options = DEFAULT_OPTIONS) => {
  const [intersectingElementId, setIntersectingElementId] = useState('');
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const intersectingElementEntry =
        entries.find((entry) => {
          return entry.isIntersecting;
        }) ?? null;
      setIntersectingElementId(intersectingElementEntry?.target?.id ?? '');
    }, options);

    elementSelectors.forEach((selector) => {
      const element = document.querySelector(selector);
      if (element && observer.current) {
        observer.current.observe(element);
      }
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [elementSelectors, options]);

  return [intersectingElementId];
};
