import React from 'react';
import Footer from './Footer/Footer.component';
import '@fontsource/kreon/400.css';
import '@fontsource/kreon/700.css';
import '@fontsource/ubuntu';
import 'react-toastify/dist/ReactToastify.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../sass/main.scss';
import Toast from './Toast/Toast.component';
import { useCookiesConsent } from '../hooks';

const Layout = ({ children }) => {
  useCookiesConsent();

  return (
    <>
      {children}
      <Toast />
      <Footer />
    </>
  )
};

export default Layout;
