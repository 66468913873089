import './TermsOfService.styles.scss';
import React from 'react';

const TermsOfService = () => {
  return (
    <div className='container terms-of-service'>
      <h1 className='terms-of-service__title heading-lg'>Terms of Service</h1>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Agreement to terms</h2>
        <p className='body-md terms-of-service__text mb-1'>
          These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on
          behalf of an entity (“you”) and Chandra Panta Chhetri (“we,” “us” or “our”), concerning your access to and use
          of the website as well as any other media form related, linked, or otherwise connected thereto (collectively,
          the “Site”).
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms
          and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited
          from using the Site and you must discontinue use immediately.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby
          expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
          modifications to these Terms and Conditions at any time and for any reason.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You
          will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any
          revised Terms and Conditions by your continued use of the Site after the date such revised Terms and
          Conditions are posted.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          The information provided on the Site is not intended for distribution to or use by any person or entity in any
          jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
          subject us to any registration requirement within such jurisdiction or country.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          Accordingly, those persons who choose to access the Site from other locations do so on their own initiative
          and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
        </p>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Intellectual property rights</h2>
        <p className='body-md terms-of-service__text mb-1'>
          Unless otherwise indicated, the Site is our proprietary property and all source code, databases,
          functionality, software, website designs, audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are
          owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various
          other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions,
          and international conventions.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except
          as expressly provided in these Terms and Conditions, no part of the Site and no Content or Marks may be
          copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
          transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever,
          without our express prior written permission.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site
          and to download or print a copy of any portion of the Content to which you have properly gained access solely
          for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site,
          the Content and the Marks.
        </p>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Prohibited activities</h2>
        <p className='body-md terms-of-service__text mb-1'>
          You may not access or use the Site for any purpose other than that for which we make the Site available. The
          Site may not be used in connection with any commercial endeavors except those that are specifically endorsed
          or approved by us.
        </p>
        <p className='body-md terms-of-service__text mb-1'>As a user of the Site, you agree not to:</p>
        <ol className='body-md terms-of-service__text'>
          <li>
            systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a
            collection, compilation, database, or directory without written permission from us.
          </li>
          <li>use the Site to advertise or offer to sell goods and services.</li>
          <li>engage in unauthorized framing of or linking to the Site.</li>
          <li>
            interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the
            Site.
          </li>
          <li>
            decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up
            a part of the Site.
          </li>
        </ol>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Third-party websites and content</h2>
        <p className='body-md terms-of-service__text mb-1'>
          The Site may contain (or you may be sent via the Site) links to other websites (“Third-Party Websites”) as
          well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or originating from third parties
          (“Third-Party Content”).
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy,
          appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed
          through the Site or any Third-Party Content posted on, available through, or installed from the Site,
          including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of
          or contained in the Third-Party Websites or the Third-Party Content.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party
          Content does not imply approval or endorsement thereof by us. If you decide to leave the Site and access the
          Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should
          be aware these Terms and Conditions no longer govern.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          You should review the applicable terms and policies, including privacy and data gathering practices, of any
          website to which you navigate from the Site or relating to any applications you use or install from the Site.
          Any purchases you make through Third-Party Websites will be through other websites and from other companies,
          and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and
          the applicable third party.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and
          you shall hold us harmless from any harm caused by your purchase of such products or services. Additionally,
          you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in
          any way from any Third-Party Content or any contact with Third-Party Websites.
        </p>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Site management</h2>
        <p className='body-md terms-of-service__text mb-1'>We reserve the right, but not the obligation, to:</p>
        <ol className='body-md terms-of-service__text'>
          <li>monitor the Site for violations of these Terms and Conditions</li>
          <li>
            take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms
            and Conditions, including without limitation, reporting such user to law enforcement authorities
          </li>
          <li>
            in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or
            disable (to the extent technologically feasible) any of your Contributions or any portion thereof
          </li>
          <li>
            in&nbsp;our sole discretion and without limitation, notice, or liability, to remove from the Site or
            otherwise disable all files and content that are excessive in size or are in any way burdensome to our
            systems;
          </li>
          <li>
            otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the
            proper functioning of the Site.
          </li>
        </ol>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Term and termination</h2>
        <p className='body-md terms-of-service__text'>
          These Terms and Conditions shall remain in full force and effect while you use the Site. Without limiting any
          other provision of these terms and conditions, we reserve the right to, in our sole discretion and without
          notice or liability, deny access to and use of the site (including blocking certain IP addresses), to any
          person for any reason or for no reason, including without limitation for breach of any representation,
          warranty, or covenant contained in these terms and conditions or of any applicable law or regulation.
        </p>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Modifications and interruptions</h2>
        <p className='body-md terms-of-service__text'>
          We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at
          our sole discretion without notice. However, we have no obligation to update any information on our Site. We
          also reserve the right to modify or discontinue all or part of the Site without notice at any time.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          We will not be liable to you or any third party for any modification or discontinuance of the Site.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other
          problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time
          or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage,
          or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of
          the Site.
        </p>
        <p className='body-md terms-of-service__text'>
          Nothing in these Terms and Conditions will be construed to obligate us to maintain and support the Site or to
          supply any corrections, updates, or releases in connection therewith.
        </p>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Corrections</h2>
        <p className='body-md terms-of-service__text'>
          There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may
          relate to the Site, including descriptions, pricing, availability, and various other information. We reserve
          the right to correct any errors, inaccuracies, or omissions and to change or update the information on the
          Site at any time, without prior notice.
        </p>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Disclaimer</h2>
        <p className='body-md terms-of-service__text'>
          The site is provided on an as-is and as-available basis. You agree that your use of the site and our services
          will be at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or
          implied, in connection with the site and your use thereof, including, without limitation, the implied
          warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranties
          or representations about the accuracy or completeness of the site’s content or the content of any websites
          linked to the site, and we will assume no liability or responsibility for any (1) errors, mistakes, or
          inaccuracies of content and materials, (2) personal injury or property damage, of any nature whatsoever,
          resulting from your access to and use of the site, (3) any unauthorized access to or use of our secure servers
          and/or any and all personal information and/or financial information stored therein, (4) any interruption or
          cessation of transmission to or from the site, (5) any bugs, viruses, trojan horses, or the like which may be
          transmitted to or through the site by any third party, and/or (6) any errors or omissions in any content and
          materials or for any loss or damage of any kind incurred as a result of the use of any content posted,
          transmitted, or otherwise made available via the site.
        </p>
      </section>
      <section className='terms-of-service__section'>
        <h2 className='terms-of-service__subheading mb-1 heading-sm'>Miscellaneous</h2>
        <p className='body-md terms-of-service__text mb-1'>
          These Terms and Conditions and any policies or operating rules posted by us on the Site constitute the entire
          agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of
          these Terms and Conditions shall not operate as a waiver of such right or provision.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          These Terms and Conditions operate to the fullest extent permissible by law. We may assign any or all of our
          rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage,
          delay, or failure to act caused by any cause beyond our reasonable control.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          If any provision or part of a provision of these Terms and Conditions is determined to be unlawful, void, or
          unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and
          does not affect the validity and enforceability of any remaining provisions.
        </p>
        <p className='body-md terms-of-service__text mb-1'>
          There is no joint venture, partnership, employment or agency relationship created between you and us as a
          result of these Terms and Conditions or use of the Site. You agree that these Terms and Conditions will not be
          construed against us by virtue of having drafted them.
        </p>
        <p className='body-md terms-of-service__text'>
          You hereby waive any and all defenses you may have based on the electronic form of these Terms and Conditions
          and the lack of signing by the parties hereto to execute these Terms and Conditions.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
