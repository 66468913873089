import React from 'react';
import './Contact.styles.scss';
import Form from './Form/ContactForm.component'

const Contact = () => {
  return (
    <section
      id='contact'
      className='contact'
    >
      <div className='container'>
        <h2 className='contact__title heading-lg'>Contact</h2>
        <div className='contact__form'>
          <Form />
        </div>
      </div>
    </section>
  );
};

export default Contact;
