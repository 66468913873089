import { graphql, useStaticQuery } from 'gatsby';

const DATE_OPTIONS = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

export const useBuildDate = () => {
  const data = useStaticQuery(graphql`
    query {
      buildDate {
        date
      }
    }
  `);

  const buildDate = new Date(data.buildDate.date).toLocaleDateString('en-US', DATE_OPTIONS);
  return buildDate;
};
