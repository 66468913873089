import React from 'react';
import './Badge.styles.scss';

const Badge = ({ children, ...otherProps }) => (
  <span
    {...otherProps}
    className='badge'
  >
    {children}
  </span>
);

export default Badge;
