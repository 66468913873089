import React from 'react';
import './Project.styles.scss';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { ENTER_KEY } from '../../../constants';
import { getProjectElementId } from '../../../utils';

const Project = ({ project, extraClasses = '', onClick }) => {
  const { title, previewImg } = project;
  const image = getImage(previewImg);
  const projectId = getProjectElementId(project);

  const onKeyDown = (event) => {
    if (event.key === ENTER_KEY) {
      onClick();
    }
  };

  return (
    <div
      role='button'
      aria-label={`Show more details about ${title}`}
      className={`project ${extraClasses}`}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={onKeyDown}
      id={projectId}
    >
      {image && (
        <GatsbyImage
          className='project__img'
          alt={`Preview of ${title}`}
          image={image}
          loading='lazy'
        />
      )}
    </div>
  );
};

export default Project;
