import React from 'react';
import './SocialIcons.styles.scss';
import Icons from '../../modules/icons';
import { sendGTAGEvent } from '../../utils';
const { GitHubIcon, LinkedinIcon } = Icons;

const SOCIALS = [
  {
    href: 'https://github.com/Chandra-Panta-Chhetri',
    title: 'github',
    Icon: GitHubIcon
  },
  {
    href: 'https://www.linkedin.com/in/chandra-panta-chhetri/',
    title: 'linkedin',
    Icon: LinkedinIcon
  }
];

const SocialIcons = ({ iconClassName = '' }) => {
  const onClick = (type) => {
    sendGTAGEvent('view_social_link', {
      type
    });
  };

  return (
    <ul
      className='social-icons'
      aria-label='social links'
    >
      {SOCIALS.map(({ title, Icon, href }) => (
        <li key={title}>
          <a
            target='_blank'
            href={href}
            rel='noopener noreferrer'
            aria-label={`View ${title} profile in new tab`}
            className={`social-icons__icon ${iconClassName}`}
            onClick={() => onClick(title)}
          >
            <Icon aria-hidden='true' />
          </a>
        </li>
      ))}
    </ul>
  );
};

export default SocialIcons;
