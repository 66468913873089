import React, { useState } from 'react';
import './Header.styles.scss';
import { Navbar } from '../../modules/bootstrap';
import Icons from '../../modules/icons';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useScrollSpy } from '../../hooks';

const { ChevronCircleDownIcon, ChevronCircleUpIcon } = Icons;

const NAV_LINKS = [
  { href: '#projects', label: 'Projects' },
  { href: '#skills', label: 'Skills' },
  { href: '#experiences', label: 'Experiences' },
  { href: '#contact', label: 'Contact' }
];

const Header = () => {
  const [isExpaned, setIsExpanded] = useState(false);
  const toggleNav = () => setIsExpanded(!isExpaned);
  const closeNav = () => setIsExpanded(false);
  const [intersectingElementId] = useScrollSpy(NAV_LINKS.map((nl) => nl.href));

  return (
    <Navbar
      expanded={isExpaned}
      expand='md'
      sticky='top'
      className={`header`}
      id={'navigation'}
    >
      <div className='header__container container'>
        <AnchorLink
          to={`/`}
          className='header__brand heading-sm'
          gatsbyLinkProps={{
            'aria-hidden': 'true',
            tabIndex: '-1'
          }}
        >
          Chandra Panta Chhetri
        </AnchorLink>
        <Navbar.Toggle
          onClick={toggleNav}
          className='header__toggle body-md'
          aria-controls='navbar-nav'
        >
          Menu{' '}
          {!isExpaned ? (
            <ChevronCircleDownIcon
              aria-hidden='true'
              className='body-lg'
            />
          ) : (
            <ChevronCircleUpIcon
              aria-hidden='true'
              className='body-lg'
            />
          )}
        </Navbar.Toggle>
        <Navbar.Collapse
          id='navbar-nav'
          className='header__collapse'
        >
          <ul
            className='header__links'
            aria-label='links'
          >
            {NAV_LINKS.map(({ href, label }) => {
              const isActive = `#${intersectingElementId}` === href;
              return (
                <li
                  key={label}
                  className={`body-lg header__link ${isActive ? 'header__link--active' : ''}`}
                >
                  <AnchorLink
                    onAnchorLinkClick={closeNav}
                    key={label}
                    to={`/${href}`}
                    title={label}
                  />
                </li>
              );
            })}
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
