import React from 'react';
import './CookiesConsent.styles.scss';
import { Link } from 'gatsby';
import { PAGES } from '../../constants';
import Button from '../Button/Button.component';

const CookiesConsent = ({ onAccept, onDecline }) => {
  return (
    <div className='cookies-consent'>
      <div className='cookies-consent__text body-md'>
        We use non-essential cookies to improve user experience and analyze website traffic. For more information,
        please read the {''}
        <Link
          to={PAGES.PRIVACY_POLICY}
          className='link'
        >
          privacy policy
        </Link>
        .
      </div>
      <div className='cookies-consent__actions'>
        <Button
          className='cookies-consent__decline'
          onClick={onDecline}
        >
          Decline
        </Button>
        <Button
          className='cookies-consent__accept'
          onClick={onAccept}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookiesConsent;
