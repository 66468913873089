import React from 'react';
import './Timeline.styles.scss';
import { useExperienceImages } from '../../hooks';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Technologies = ({ technologies }) => {
  return (
    <ul
      className='timeline__technologies'
      aria-label={'technologies'}
    >
      {technologies.map(({ Icon, label }) => (
        <li
          key={label}
          className='timeline__technology-icon'
        >
          <Icon aria-hidden='true' />
          <div className='body-sm'>{label}</div>
        </li>
      ))}
    </ul>
  );
};

const Timeline = ({ events = [] }) => {
  const images = useExperienceImages();

  return (
    <div className='timeline'>
      {events.map(({ title, employer, linkToEmployer, period, technologies, imgName, teams, description }, i) => {
        const img = images.find((i) => i.name === imgName) || null;
        const imgData = getImage(img);
        return (
          <div
            key={i}
            className={`timeline__event`}
          >
            <div
              className='timeline__image'
              aria-hidden='true'
            >
              {imgData && (
                <GatsbyImage
                  alt={`${employer} logo`}
                  image={imgData}
                  loading='lazy'
                />
              )}
            </div>
            <div className='timeline__details'>
              <h3 className='heading-sm timeline__title'>{title}</h3>
              <div className='timeline__company'>
                {linkToEmployer ? (
                  <a
                    href={linkToEmployer}
                    className='link body-lg'
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label={`View ${employer} website in new tab`}
                  >
                    {employer}
                  </a>
                ) : (
                  <span className='body-lg'>{employer}</span>
                )}
                <span className='body-lg timeline__date'>({period})</span>
                {description && <p className='timeline__description body-md'>{description}</p>}
              </div>
              {teams ? (
                <ul
                  className='timeline__teams'
                  aria-label='teams'
                >
                  {teams.map(({ name, technologies, description }, i) => (
                    <li key={`${name}-${i}`}>
                      <h4 className='body-lg'>{name}</h4>
                      <p className='body-md timeline__description'>{description}</p>
                      <Technologies technologies={technologies} />
                    </li>
                  ))}
                </ul>
              ) : (
                <Technologies technologies={technologies} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
