export const PROJECTS = [
  {
    title: 'Tokyo Treasures',
    category: 'web',
    previewImgName: 'tokyo_treasures',
    techStack: [
      'Tailwind CSS',
      'Shadcn',
      'Gatsby',
      'Github Actions',
      'Google Analytics',
      'ESLint',
      'TypeScript',
      'Figma'
    ],
    description: 'A responsive Japanese restaurant website that emphasizes performance, SEO, and accessibility.',
    highlights: [
      'Designed wireframes and UI in Figma, drawing inspiration from platforms such as Dribbble, Behance, and UI8.',
      'Implemented a GDPR-compliant cookie consent integrated with Google Analytics.',
      "Added CSS animations triggered via the Intersection Observer API, ensuring animations respect users' reduced motion preferences.",
      'Ensured compliance with WCAG 2.1 standards using the IBM Equal Access Checker browser extension and the Accessibility Tree View in Chrome DevTools.',
      'Automated deployments to Cloudflare Pages using GitHub Actions, maintaining separate environments for production and development.'
    ],
    sourceLink: 'https://github.com/Chandra-Panta-Chhetri/tokyo-treasures',
    demoLink: 'https://tokyo-treasures.pages.dev',
    designLink: 'https://www.figma.com/design/HoPdh5o6sjGMkdP9dRhJbU/Tokyo-Treasures'
  }
];
