import React from 'react';
import { ToastContainer } from 'react-toastify';
import Icons from '../../modules/icons';
import './Toast.styles.scss';
import { ENTER_KEY } from '../../constants';
import { useMediaQuery } from '../../hooks';

const { CloseIcon, WarningIcon, SuccessIcon, InfoIcon, ErrorIcon } = Icons;

const CloseButton = (props) => {
  const onEnterKeyDown = (event) => {
    if (event.key === ENTER_KEY && props.closeToast) {
      props.closeToast();
    }
  };

  return (
    <CloseIcon
      className='toast__close heading-sm'
      role='button'
      aria-label='Close alert'
      tabIndex={0}
      onKeyDown={onEnterKeyDown}
    />
  );
};

const Icon = ({ type }) => {
  switch (type) {
    case 'info':
      return (
        <InfoIcon
          className='toast__info heading-sm'
          aria-hidden='true'
        />
      );
    case 'error':
      return (
        <ErrorIcon
          className='toast__error heading-sm'
          aria-hidden='true'
        />
      );
    case 'success':
      return (
        <SuccessIcon
          className='toast__success heading-sm'
          aria-hidden='true'
        />
      );
    case 'warning':
      return (
        <WarningIcon
          className='toast__warning heading-sm'
          aria-hidden='true'
        />
      );
    default:
      return null;
  }
};

const Toast = () => {
  const isMobile = useMediaQuery('(max-width: 576px)');

  return (
    <div role='region' aria-label="Notifications" aria-live='polite'>
      <ToastContainer
        position='bottom-right'
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={true}
        closeButton={isMobile ? false : CloseButton}
        bodyClassName='body-md toast__body'
        limit={1}
        icon={Icon}
      />
    </div>
  );
};

export default Toast;
